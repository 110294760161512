.react-calendar {
  width: 322px !important;
  height: 310px;
  max-width: 100%;
  background: white;
  /* border: 1px solid #a0a096; */
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  @apply border border-neutral-40;
  @apply shadow-card-box;
  @apply rounded-lg;
  @apply p-6;
  @apply font-tbcx-regular text-2sm;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 24px;
  margin-bottom: 0.5rem;
}

.react-calendar__navigation button {
  min-width: 24px;
  background: none;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  /* background-color: #e6e6e6; */
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  /* padding: 2em 0.5em; */
}

/* @apply h-8 w-8 rounded-full; */

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* background-color: #e6e6e6; */
}

.react-calendar__tile--now {
  /* background: #9759b4 !important; */
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  /* background: #ffffa9; */
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  /* background: #a9d4ff; */
}

.react-calendar__tile--active {
  color: white !important;
  @apply bg-primary-100 !important;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  @apply text-white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

/* DAY PICK DAY DAY DAY */
.react-calendar__month-view__days {
  @apply gap-x-2 gap-y-[1px];
}
.react-calendar__month-view__days__day {
  flex: none !important;
  @apply flex h-8 w-8 items-center justify-center rounded-full;
}
.react-calendar__month-view__days__day:enabled:hover,
.react-calendar__month-view__days__day:enabled:focus {
  @apply text-primary-100 outline  outline-primary-100;
}

.react-calendar__month-view__days__day--weekend {
  /* color: #d10000; */
}

.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-secondary-40;
}

.react-calendar__year-view__months {
  @apply gap-y-6 pt-4;
}

.react-calendar__year-view__months button {
  height: 33px;
}

.react-calendar__tile:hover {
  @apply rounded-full text-primary-100 outline outline-1 outline-primary-100;
}
.react-calendar__tile--hasActive {
  @apply rounded-full bg-primary-100 !text-white;
}

.react-calendar__month-view__weekdays__weekday,
.react-calendar__navigation__label__labelText--from {
  @apply font-tbcx-bold text-2sm normal-case text-secondary-100;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

/* IMPORTANT LIVE BOTTOM */
.react-calendar__tile:disabled,
.react-calendar__tile:disabled:hover {
  background-color: #f0f0f0;
  border: none;
  outline: none;
  border-radius: 9999px;
  opacity: 30%;
  color: black;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__decade-view__years {
  @apply gap-y-1 pt-4;
}
.react-calendar__decade-view__years__year {
  flex: 0 0 25% !important;
}