.mobile-menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 72px;
  background-color: #fff;
  display: none;
  justify-content: space-around;
  align-items: center;
  z-index: 10;
}

.mobile-menu ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: space-around;
}

.mobile-menu span {
  margin-top: 5px;
}

.mobile-menu .dot-icon {
  position: absolute;
  bottom: -4px;
  margin: 0 auto;
  width: 4px;
  height: 4px;
}

.mobile-menu a {
  color: #686a73;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
}

.mobile-menu .menu-image {
  width: 24px;
  height: 24px;
}

.mobile-menu .active-icon .fill path {
  fill: #a155b9;
}

.mobile-menu .active-icon .stroke path {
  stroke: #a155b9;
}

.mobile-search {
  background-color: rgb(251 251 251 / var(--tw-bg-opacity));
}

.mobile-search .job-type-dropdown,
.mobile-search .location-dropdown {
  display: block;
}

.mobile-search .box-wrapper {
  height: 80vh;
  border: none;
}

.mobile-search .search-wrapper {
  flex-direction: column;
}

.mobile-search .filter-buttons {
  display: none;
}

.mobile-search .keyword-dropdown,
.mobile-search .job-type-dropdown,
.mobile-search .location-dropdown {
  height: 65px;
  border: solid 1px #dddddd;
  flex: none;
  background-color: #fff;
  margin-top: 16px;
  border-radius: 12px;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  --tw-shadow-colored: 0px 5px 15px var(--tw-shadow-color);
}

.mobile-search .back {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: solid 1px #bdbdbd;
  padding: 6px;
}

.detail-filter-button,
.detail-filter-button:hover {
  width: 100%;
  align-self: flex-end;
  background-color: #eeeeee;
  margin-top: 60px;
  color: #222222;
  height: 50px;
  border-radius: 58px;
}

#register-wizard-box {
  height: auto;
  max-height: fit-content;
  min-height: 500px;
}

#register-wizard-box .wizard-step-modal {
  /* min-height: 500px; */
  height: auto;
}

.profile-modal-wrapper .skip-button-wrapper {
  display: none !important;
}

.react-calendar__month-view__days__day {
  width: 1.8rem !important;
}

.top-vacancy-box {
  margin-right: 20px !important;
  width: 295px !important;
}

.react-datepicker-popper { 
  z-index: 1000 !important; 
}

@media (min-width: 1024px) {
  .picker-type-month .react-datepicker-popper {
    bottom: 0px !important;
    top: auto !important;
  }
}

@media (max-width: 1024px) {
  #register-wizard-box {
    margin-bottom: 100px;
  }

  #register-wizard-box form { 
    overflow: auto;
  }

  .top-vacancy-box {
    margin-right: 20px !important;
    width: 235px !important;
  }

  .react-calendar__month-view__days__day {
    width: 2rem !important;
  }

  .wizard-step-modal .react-calendar {
    top: -6rem !important;
  }

  #register-wizard-box {
    min-height: auto;
  }

  #register-wizard-box .wizard-step-modal {
    min-height: auto;
    padding-left: 0px;
    padding-right: 0px;
  }

  #headlessui-portal-root .wizard-step-modal {
    overflow: auto;
    padding-bottom: 0px;
    height: calc(90vh - 170px);
  }

  .registration-progress-bar {
    border: none;
    margin-left: -22px;
    width: 100vw;
    position: sticky;
    top: 70px;
    background-color: white;
    z-index: 22;
    box-shadow: 0px 5px 15px 0px #0000000d;
  }

  .wizard-actions {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 15px 0;
    z-index: 22;
  }

  .wizard-navigation-bar,
  .wizard-actions {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0px;
    background-color: white;
    padding: 16px;
    box-shadow: 0px -5px 15px 0px #0000000d;
  }

  #form-additional-information {
    padding-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .companies-paginate {
    li,
    li a {
      height: 32px;
      width: 32px;
    }
  }

  .vacancy-content-bar {
    z-index: 20;
  }

  .skills-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .scrollbar-hidden {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .swiper-container {
    width: 768px;
  }

  .mobile-menu {
    display: flex;
  }

  .detailed-filter-wrapper {
    padding: 0;
    align-items: end;
  }

  .detailed-filter {
    max-height: calc(100% - 50px);
    border-radius: 14px 14px 0 0;
  }

  .salary-range {
    width: auto;
  }

  .salary-range-slider,
  .salary-range-bars {
    padding: 0;
  }

  .top-vacancy-box {
    margin-right: 20px !important;
    width: 295px !important;
  }

  .vacancy-filter-wrapper {
    height: 65px;
  }

  .custom-shadow {
    box-shadow: 0px -4px 12px 0px #00000014;
  }

  .vacancies-quantity {
    height: 44px;
    color: #222222;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-left: 16px;
  }

  .vacancy-filters {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .mini-salary-range {
    width: auto;
  }
}

/* Responsive Pagination */
.react-paginate {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  gap: 0.25rem;
}

@media (min-width: 640px) {
  .react-paginate {
    gap: 0.5rem;
  }
}

.react-paginate li {
  display: inline;
}

.react-paginate a {
  display: block;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  text-decoration: none;
}

@media (min-width: 640px) {
  .react-paginate a {
    padding: 0.5rem 1rem;
  }
}

.custom-scroll::-webkit-scrollbar {
  width: 4px; /* Width of the entire scrollbar */
}

.custom-scroll::-webkit-scrollbar-track {
  background: #f6f6f6; /* Color of the track */
  border-radius: 24px;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background-color: #f6f6f6;
  border-radius: 24px;
  border: 2px solid #f6f6f6;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: #f6f6f6 #f6f6f6;
}
